<template>
  <div id="permission-assign" v-if="permissions">
    <h3 class="text-center py-2 text-capitalize" v-if="role">
      Assign Permission To Role : {{ role.name === 'customer' ? 'client' : role.name }}
    </h3>
    <div>
      <b-row class="mx-1">
        <b-col cols="12" md="6" class="p-0 text-capitalize mb-2">
          <b-form-group id="" label="Roles" label-for="Roles">
            <v-select
              v-model="role"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="name"
              :state="nameState"
              :options="roles"
              placeholder="Select Role"
              @input="getRole(role.id)"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <div class="row m-0">
        <div
          v-for="(permission, index) in permissions"
          :key="index"
          class="col-md-6"
        >
          <b-card
            :title="`Module Name - ${permission.module}`"
            header-tag="header"
            footer-tag="footer"
          >
            <div class="custom-body">
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  :id="`checkbox-group-${index}`"
                  v-model="selected"
                  :options="permission.permission"
                  :aria-describedby="ariaDescribedby"
                      class="mb-1 d-flex flex-column "
                      value-field="id"
                      text-field="name"
                    ></b-form-checkbox-group>
                  </b-form-group>
                </div>
              </b-card>
            </div>
          </div>
          <button
            class="btn btn-primary text-center d-block mx-auto my-3"
            @click="assignPermission"
            v-if="$can('update', 'Role Permission')"
            :disabled="role.name === 'superadmin'"
          >
            Assign Permission
          </button>
        </div>
      </div>
</template>
<script>
import { toast } from "@/utils/toast";
import {
  BButton,
  BCard,
  BCardGroup,
  BCardText,
  BCol,
  BFormCheckboxGroup,
  BFormGroup,
  BRow,
} from "bootstrap-vue";
import vSelect from "vue-select";
export default {
  data() {
    return {
      role: {},
      permissions: [],
      roles: [],
      selected: [],
      query: {
        keyword: "",
        page: 1,
        per_page: 20,
      },
      nameState: null,
      disabledItems: [1, 2],
    };
  },
  components: {
    BCard,
    BCardGroup,
    BCard,
    BCardText,
    BButton,
    BFormGroup,
    BFormCheckboxGroup,
    BRow,
    BCol,
    vSelect,
  },
  created() {
    const Id = this.$route.params.roleId;
    this.getRole(Id);
    this.getRoles();
  },
  mounted() {
    this.disabledItems = [1, 2];
  },
  methods: {
    async getRoles() {
      await this.$store
        .dispatch("role/getRoles", this.query)
        .then((response) => {
          let data = response.data;
          let roles = []
          data.forEach((element) => {
            if(element.name === 'customer') {
              element.name = 'client';
            }
            roles.push(element);
          });
          this.roles = roles;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getRole(id) {
      this.$store.dispatch("role/getRole", id).then((res) => {
        if(res.data.role.name === 'customer') {
          res.data.role.name = 'client';
        }
        this.role = res.data.role;
        let permissions = res.data.permissions;
        this.selected = res.data.role.permissions.map(
          (permission) => permission.id
        );
        let Arr = [];
        permissions.forEach((element) => {
          let newName = element.name + "-" + element.id;
          let arr = newName.split("-");
          // make an object group by module name
          let obj = {
            module: arr[0],
            permission: {
              name: arr[1],
              id: arr[2],
            },
          };
          Arr.push(obj);
        });
        // group by module name
        let obj = {
          module: "",
          permission: [],
        };
        let newArr = [];
        Arr.forEach((element, index) => {
          if (obj.module === element.module) {
            obj.permission.push(element.permission);
          } else {
            // if module name already exist in newArr  without loop
            if (newArr.some((e) => e.module === element.module)) {
              newArr.forEach((e) => {
                if (e.module === element.module) {
                  e.permission.push(element.permission);
                }
              });
            } else {
              // if module name not exist in newArr
              obj = {
                module: element.module,
                permission: [element.permission],
              };
              newArr.push(obj);
            }
          }
        });
        this.permissions = newArr;
      });
    },
    assignPermission() {
      let data = {
        role_id: this.role.id,
        permission_name: this.selected,
      };
      this.$store
        .dispatch("role/assignPermissions", data)
        .then((res) => {
          if (res.status_code === 200) {
            toast(
              "Success",
              "CheckCircleIcon",
              "success",
              "Permission Updated Successfully"
            );
          }
        })
        .catch((err) => {
          console.log(err);
          toast(
            "Error",
            "AlertTriangleIcon",
            "danger",
            "Error Updating Permission"
          );
        });
    },
  },
};
</script>
<style scoped>
[dir] .card .card-title {
  margin-bottom: 1.53rem;
  background: #ddd;
  padding: 10px;
  text-transform: capitalize;
}
[dir] .card-body {
  padding: 0px !important;
}
.custom-body {
  padding: 10px;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
